import './custom.scss';

//@ts-ignore
import * as bootstrap from '../../../node_modules/bootstrap/js/index.umd';
import ElectronicknowBankAccount from './electronicknow/ElectronicknowBankAccount';
import IncrementalSearch from './lib/IncrementalSearch';
import './yubinbango';

console.log("Hello World!");

document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(element => new bootstrap.Tooltip(element));

document.addEventListener('DOMContentLoaded', (event) => {
    IncrementalSearch.initComponent();
    ElectronicknowBankAccount.initComponent();
});
