export default class IncrementalSearch {

    public static initComponent() {
        document.querySelectorAll<HTMLInputElement>("[data-action~=IncrementalSearch]").forEach((element) => {
            new IncrementalSearch(element);
        });
    }

    private valueElement: HTMLInputElement;
    private clearButtonElement: HTMLElement;
    private dropdownElement: HTMLElement;
    private dropdownMenuElement: HTMLElement;

    private initValue: string;

    private data: any;
    private dataItemMap = new Map<any, any>();

    public constructor(element: HTMLInputElement) {
        this.valueElement = element;

        this.initValue = this.valueElement.value;

        this.setupElement();
        this.setupEvent();
    }

    private setupEvent() {
        this.valueElement.addEventListener("keydown", (event) => {
            if (event.key == "Escape") {
                event.preventDefault();
                this.escapeKeydown();
                return;
            }

            if (event.key == "ArrowUp") {
                event.preventDefault();
                this.arrowUpKeydown();
                return;
            }

            if (event.key == "ArrowDown") {
                event.preventDefault();
                this.arrowDownKeydown();
                return;
            }

            if (event.key == "Enter") {
                event.preventDefault();
                this.enterKeydown();
                return;
            }
        });

        this.valueElement.addEventListener("input", (event) => {
            event.preventDefault();
            this.inputValueElement();
        });

        this.valueElement.addEventListener("blur", (event) => {
            event.preventDefault();
            this.blurValueElement(event);
        });

        this.dropdownMenuElement.addEventListener("click", (event) => {
            event.preventDefault();
            this.clickDropdownMenu(event);
        });

        this.clearButtonElement.addEventListener("click", (event) => {
            event.preventDefault();
            this.clickClearButton();
        });
    }

    private escapeKeydown() {
        this.setInitValue();
    }

    private arrowUpKeydown() {
        const activeElement = this.dropdownMenuElement.querySelector(".active");  // a タグ
        if (activeElement) {
            const parentElement = activeElement.parentElement;  // li タグ
            if (parentElement.previousElementSibling) {
                activeElement.classList.remove("active");
                parentElement.previousElementSibling.firstElementChild.classList.add("active");
                parentElement.previousElementSibling.scrollIntoView({block: "nearest"});
            }
        }
    }

    private arrowDownKeydown() {
        const activeElement = this.dropdownMenuElement.querySelector(".active");  // a タグ
        if (activeElement) {
            const parentElement = activeElement.parentElement;  // li タグ
            if (parentElement.nextElementSibling) {
                activeElement.classList.remove("active");
                parentElement.nextElementSibling.firstElementChild.classList.add("active");
                parentElement.nextElementSibling.scrollIntoView({block: "nearest"});
            }
        } else {
            const firstElement = this.dropdownMenuElement.querySelector("a");
            if (firstElement) {
                firstElement.classList.add("active");
                firstElement.parentElement.scrollIntoView({block: "nearest"});
            }
        }
    }

    private enterKeydown() {
        const blurEvent = new Event("blur");
        this.valueElement.dispatchEvent(blurEvent);
    }

    private async blurValueElement(event: Event) {
        // 200 ミリ秒 sleep
        await this.sleep();

        if (!this.dropdownMenuElement.classList.contains("show")) {
            return;
        }

        var active = this.dropdownMenuElement.querySelector(".active");
        if (active) {
            let item = this.dataItemMap.get(active);
            this.setValue(item);

        } else {
            let menuItems = this.dropdownMenuElement.querySelectorAll(".dropdown-item");
            if (menuItems.length == 1) {
                let item = this.dataItemMap.values().next().value;
                this.setValue(item);

            } else if (menuItems.length == null || menuItems.length > 1) {
                this.setEmptyValue();
            }
        }
        this.hideDropdownMenu()
    }

    private sleep() {
        return new Promise(resolve => setTimeout(resolve, 200));
    }

    private inputValueElement() {
        let query = this.valueElement.value.trim().replace(/[\s　]+/g, ' ');

        if (0 < query.length) {
            this.executeQuery(query);

        } else if (query.length == 0) {
            this.hideDropdownMenu();
        }
    }

    private clickDropdownMenu(event: Event) {
        var active = this.dropdownMenuElement.querySelector(".active");

        if (active && event.target != active) {
            active.classList.remove("active");
        }

        if (event.target instanceof HTMLElement) {
            event.target.classList.add("active");
            event.target.scrollIntoView({block: "nearest"});
        }
    }

    private clickClearButton() {
        this.setInitValue();
        this.valueElement.focus();
    }

    private async executeQuery(query: string) {

        this.clearDropdownItemElements();

        if (this.data === undefined) {
            const apiUrl = this.valueElement.dataset["apiUrl"];
            this.data = await (await fetch(apiUrl)).json();
        }

        const keywords = query.split(/[ 　]+/);

        let show = false;

        for (const item of this.data) {
            if (keywords.some(keywords => item.content.includes(keywords))) {
                let dropdownItemElement = this.createDropdownItemElement(item);
                this.dropdownMenuElement.appendChild(dropdownItemElement);
                show = true;
            }
        }

        if (show) {
            this.dropdownMenuElement.classList.add("show");
        }
    }

    private hideDropdownMenu() {
        this.clearDropdownItemElements();
        this.dropdownMenuElement.classList.remove("show");
        this.data = undefined;
    }

    private createDropdownItemElement(item: any) {
        let dropdownItemElement = document.createElement("a");
        dropdownItemElement.classList.add("dropdown-item");
        dropdownItemElement.text = item.text;

        this.dataItemMap.set(dropdownItemElement, item);

        let liElement = document.createElement("li")
        liElement.appendChild(dropdownItemElement);
        return liElement;
    }

    private setInitValue() {
        this.valueElement.value = this.initValue;
        this.hideDropdownMenu();
    }

    private setEmptyValue() {
        this.valueElement.value = "";
        this.hideDropdownMenu();
    }

    private setValue(item: any) {
        let fireEvent = item.value != this.valueElement.value;

        this.valueElement.value = item.value;
        this.initValue = item.value;

        if (fireEvent) {
            let event = new Event("change");
            this.valueElement.dispatchEvent(event);
        }

        let isearchChangeEvent = new CustomEvent("isearch-change", { detail: item, bubbles: true });
        this.valueElement.dispatchEvent(isearchChangeEvent);
    }

    private clearDropdownItemElements() {
        while (this.dropdownMenuElement.childNodes.length > 0) {
            this.dropdownMenuElement.removeChild(this.dropdownMenuElement.firstChild);
        }

        this.dataItemMap.clear();
    }

    private setupElement() {
        const parentElement = this.valueElement.parentElement;
        const nextElementSibling = this.valueElement.nextElementSibling

        this.clearButtonElement = this.createClearButtonElement();

        const inputGroupElement = this.createInputGroupElement();
        inputGroupElement.appendChild(this.valueElement);
        inputGroupElement.appendChild(this.clearButtonElement);
        if (nextElementSibling) {
            inputGroupElement.appendChild(nextElementSibling);
        }

        this.dropdownMenuElement = this.createDropdownMenuElement();

        this.dropdownElement = this.createDropdownElement();
        this.dropdownElement.appendChild(inputGroupElement);
        this.dropdownElement.appendChild(this.dropdownMenuElement);

        parentElement.appendChild(this.dropdownElement);
    }

    private createDropdownElement(): HTMLDivElement {
        const dropdownElement = document.createElement("div");
        dropdownElement.classList.add("dropdown");
        return dropdownElement;
    }

    private createInputGroupElement(): HTMLDivElement {
        const dropdownElement = document.createElement("div");
        dropdownElement.classList.add("input-group");
        dropdownElement.classList.add("has-validation");
        return dropdownElement;
    }

    private createClearButtonElement(): HTMLSpanElement {
        const clearButtonElement = document.createElement("span");
        clearButtonElement.classList.add("input-group-text");
        clearButtonElement.textContent = "×";

        return clearButtonElement;
    }

    private createDropdownMenuElement(): HTMLUListElement {
        const dropdownMenuElement = document.createElement("ul");
        dropdownMenuElement.classList.add("dropdown-menu");
        dropdownMenuElement.classList.add("overflow-auto");
        dropdownMenuElement.style.maxHeight = "320px";
        return dropdownMenuElement;
    }
}
