import KinyuKikanMei from "./KinyuKikanMei";

export default class ElectronicknowBankAccount {

    public static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action~=ElectronicknowBankAccount]").forEach((element) => {
            new ElectronicknowBankAccount(element);
        });
    }

    private electronicknowBankAccountElement: HTMLElement;

    public constructor(element: HTMLElement) {
        this.electronicknowBankAccountElement = element;

        KinyuKikanMei.initComponent(this.electronicknowBankAccountElement);
    }
}
