export default class KinyuKikanMei {

    public static initComponent(parentElement: HTMLElement) {
        document.querySelectorAll<HTMLInputElement>("[data-action~=KinyuKikanMei]").forEach((element) => {
            new KinyuKikanMei(element, parentElement);
        });
    }

    private parentElement: HTMLElement;
    private kinyuKikanMeiElement: HTMLInputElement;

    public constructor(element: HTMLInputElement, parentElement: HTMLElement) {
        this.kinyuKikanMeiElement = element;
        this.parentElement = parentElement;

        this.kinyuKikanMeiElement.addEventListener("isearch-change", (event) => {
            this.isearchChange(event as CustomEvent);
        });
    }

    private async isearchChange(event: CustomEvent) {
        const shitenApiUrl = this.kinyuKikanMeiElement.dataset["shitenApiUrl"] as string;
        const apiUrl = shitenApiUrl.replace("{bankCode}", event.detail.code);

        const shitenMeiElement = this.parentElement.querySelector("#shitenMei") as HTMLInputElement
        shitenMeiElement.dataset["apiUrl"] = apiUrl;
    }
}